var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box content"},[_c('page-title',{attrs:{"text":_vm.title}}),_c('p',[_vm._v(_vm._s(_vm.text))]),_c('form',{staticClass:"widden",on:{"submit":function($event){$event.preventDefault();}}},[_c('combobox-task-type',{attrs:{"task-type-list":_vm.isAssetTasks ? _vm.productionAssetTaskTypes : _vm.productionShotTaskTypes},model:{value:(_vm.form.task_type_id),callback:function ($$v) {_vm.$set(_vm.form, "task_type_id", $$v)},expression:"form.task_type_id"}})],1),_c('div',{staticClass:"flexrow"},[_c('div',{staticClass:"filler"}),_c('combobox',{staticClass:"flexrow-item",attrs:{"options":_vm.selectionOptions,"with-margin":false},model:{value:(_vm.selectionOnly),callback:function ($$v) {_vm.selectionOnly=$$v},expression:"selectionOnly"}}),_c('a',{class:{
            button: true,
            'flexrow-item': true,
            'is-primary': true,
            'is-loading': _vm.isLoadingStay
          },on:{"click":_vm.confirmAndStayClicked}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation_and_stay"))+" ")]),_c('a',{class:{
            button: true,
            'flexrow-item': true,
            'is-primary': true,
            'is-loading': _vm.isLoading
          },on:{"click":_vm.confirmClicked}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])],1),(_vm.isError)?_c('p',{staticClass:"error has-text-right info-message"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }